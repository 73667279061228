
  import { defineComponent, ref, watch } from 'vue'
  import { ScpButton, ScpProcessPagination, ScpModal } from '@rmh-media/scp-components'
  import Information from '@/components/createCalendarEntry/Information.vue'
  import DateAndTime from '@/components/createCalendarEntry/DateAndTime.vue'
  import Location from '@/components/createCalendarEntry/Location.vue'

  export default defineComponent({
    name: 'Create',
    components: {
      Location,
      Information,
      DateAndTime,
      ScpButton,
      ScpProcessPagination,
      ScpModal,
    },
    props: {
      briefingServiceData: {
        type: Object,
        required: true,
      },
    },

    setup(props): Record<string, unknown> {
      const loading = ref(false)
      const step = ref(1)
      const enableContinueButton = ref(false)
      const title = 'Create a Calendar Entry'
      const description =
        'This is an example text as this dialogue can be used for multiple purposes and can include multiple sentences.'
      // Data Object with default values
      const data = ref({
        title: '',
        description: '',
        start: '',
        end: '',
        alert: '0',
        location: '',
      })
      // Stages with title and indicator for the process-pagination
      const stages = [
        {
          title: 'Information',
          indicator: '01',
        },
        {
          title: 'Date & Time',
          indicator: '02',
        },
        {
          title: 'Location',
          indicator: '03',
        },
      ]

      /**
       * Fetching Data to the ics-generator Service and post a message to API with details
       */
      const createIcsFile = () => {
        loading.value = true

        const url = props.briefingServiceData.backendUrl + '/api/v1/service/ics/generate'

        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + props.briefingServiceData.userToken,
          },
          credentials: 'include',
          body: getStringifiedData(),
        })
          .then(response => response.json())
          .then(data => {
            message('executeBriefingServiceAction', {
              title: data.title,
              start: data.start,
              url: data.url,
            })

            loading.value = false
          })
      }

      /**
       * A helper functions to make a postMessage
       * @param command
       * @param argument
       */
      const message = (command: string, argument: any) => {
        window.parent.postMessage(
          {
            command: command,
            argument: argument,
          },
          '*'
        )
      }

      /**
       * On saving we create the ICS File
       */
      const onSave = () => {
        createIcsFile()
      }

      /**
       * Closes the service Window
       */
      const onClose = () => {
        message('rejectBriefingServiceResult', 'closed')
      }

      /**
       * @onStepUp - going a step forward (Component)
       * @onStepDown - going a step back (Component)
       */
      const onStepUp = () => {
        step.value += 1
      }
      const onStepDown = () => {
        step.value -= 1
      }

      /**
       * Getting Called when Component Data is refreshed
       * @param prop
       */
      const onUpdate = (prop: Record<string, Record<string, string>>) => {
        Object.assign(data.value, prop)
        validation()
      }

      /**
       * Check validation of fields and enables the 'continue' button
       * Step 1: Checking if mandatory field 'title' is given
       * Step 2: Does Step 1 + Checking if mandatory field 'start', 'end', 'alert' is given
       * Step 2: Does Step 1 and 2 + Checking if mandatory field 'location' is given
       */
      const validation = () => {
        if (step.value >= 1) {
          enableContinueButton.value = !!data.value.title
        }

        if (step.value >= 2) {
          enableContinueButton.value = !!(data.value.start && data.value.end && data.value.alert)
        }

        if (step.value >= 3) {
          enableContinueButton.value = !!data.value.location
        }
      }

      /**
       * Return Data for ICS File and stringifies it
       */
      const getStringifiedData = () => {
        return JSON.stringify({
          start: data.value.start,
          end: data.value.end,
          title: data.value.title,
          description: data.value.description,
          location: data.value.location,
          alert: data.value.alert,
        })
      }

      /**
       * Keeps validation alive
       */
      watch(
        step,
        () => {
          validation()
        },
        { immediate: true, deep: true }
      )

      return {
        loading,
        title,
        description,
        data,
        enableContinueButton,
        step,
        stages,
        onUpdate,
        onStepUp,
        onStepDown,
        onSave,
        onClose,
        createIcsFile,
      }
    },
  })
