
  import { defineComponent, reactive, ref, computed } from 'vue'
  import { ScpInput, ScpSelect } from '@rmh-media/scp-components'
  import moment from 'moment'

  export default defineComponent({
    name: 'DateAndTime',
    components: {
      ScpInput,
      ScpSelect,
    },
    props: {
      start: {
        required: true,
        default: '',
        type: String,
      },
      end: {
        required: true,
        default: '',
        type: String,
      },
      alert: {
        required: true,
        default: '0',
        type: String,
      },
    },
    emits: ['update'],

    /**
     * When changing the AlertItems value keep the format!
     * D - Days
     * H - Hours
     * M - Minutes
     * @param props
     * @param emit
     */
    setup(props: Record<string, unknown>, { emit }): Record<string, unknown> {
      props = reactive(props)
      const start = ref(props.start)
      const startTime = ref('09:00')
      const end = ref(props.end)
      const endTime = ref('16:00')
      const isError = ref(false)
      const alert = ref(props.alert)
      const currentDate = moment().add(14, 'days').toISOString().split('T')[0].toString()
      // Collection of available Alert Items
      const alertItems = [
        { name: 'Without', value: '0' },
        { name: '1 Day before', value: '1D' },
        { name: '2 Days before', value: '2D' },
        { name: '3 Days before', value: '3D' },
        { name: '4 Days before', value: '4D' },
        { name: '1 Week before', value: '7D' },
        { name: '2 Weeks before', value: '14D' },
      ]
      const alertLabel = ref(alertItems[0].value)
      const dateFormat = 'YYYY-MM-DDTHH:mmZ'

      /**
       * Sets the inidividual time on a date
       * @param date
       * @param time
       */
      const setIndividualTime = (date: string, time: string) => {
        let times = time.split(':')
        if (!date || !time.includes(':') || times[0].length < 2 || times[1].length < 2) {
          return ''
        }

        const hours = parseInt(time.split(':')[0])
        const minutes = parseInt(time.split(':')[1])
        const d = moment(date).hour(hours).minute(minutes)
        return d.isValid() ? d.format(dateFormat) : date
      }

      /**
       * Validates Data if they are valid and values are set
       */
      const validData = () => {
        return (
          start.value &&
          moment(start.value as string).isValid() &&
          end.value &&
          moment(end.value as string).isValid() &&
          alert.value
        )
      }

      /**
       * Updates the Start date
       * @param val
       */
      const onUpdateStartDate = (val: Date) => {
        const value = setIndividualTime(moment(val).toISOString(), startTime.value as string)
        start.value = value

        if (!alert.value) {
          alert.value = value
        }

        onUpdate()
      }

      /**
       * Updates the End date
       * @param val
       */
      const onUpdateEndDate = (val: Date) => {
        end.value = setIndividualTime(moment(val).toISOString(), endTime.value as string)
        onUpdate()
      }

      /**
       * Updates the startTime and set individual time for Start date
       * @param val
       */
      const onUpdateStartTime = (val: string) => {
        startTime.value = val
        const date = setIndividualTime(start.value as string, val)

        if (!date) {
          showError(true)
          emptyUpdate()
          return
        }

        start.value = date
        onUpdate()
      }

      /**
       * Updates the endTime and set individual time for End date
       * @param val
       */
      const onUpdateEndTime = (val: string) => {
        endTime.value = val
        const date = setIndividualTime(end.value as string, val)

        if (!date) {
          showError(true)
          emptyUpdate()
          return
        }

        end.value = date
        onUpdate()
      }

      /**
       * Updates for the Alert field
       * @param val
       */
      const onUpdateAlert = (val: string) => {
        alert.value = val
        onUpdate()
      }

      /**
       * Shows Errors if invalid start and end date
       * It is also forceable
       * @param force
       */
      const showError = (force = false) => {
        if (force) {
          isError.value = true
          return isError.value
        }

        if (!start.value && !startTime.value && !end.value && !endTime.value) {
          return false
        }

        const s = moment(start.value as string)
        const e = moment(end.value as string)

        isError.value = s.diff(e) >= 0
        return isError.value
      }

      /**
       * Updates Create Component with empty values
       */
      const emptyUpdate = () => {
        emit('update', {
          start: '',
          end: '',
          alert: '',
        })
      }

      /**
       * Getting Called by updating 'start', 'startTime', 'end', 'endTime' and 'alert' field
       * Emits the data to Create Component
       */
      const onUpdate = () => {
        if (showError()) {
          emptyUpdate()
          return
        }

        if (!validData()) {
          return
        }

        emit('update', {
          start: start.value,
          end: end.value,
          alert: alert.value,
        })
      }

      /**
       * Sets default Values for start and end
       */
      const setDefaultValues = () => {
        start.value = setIndividualTime(currentDate as string, startTime.value)
        end.value = setIndividualTime(currentDate as string, endTime.value)
      }

      // Sets default values and updates Create Component to enable the 'continue' button
      setDefaultValues()
      onUpdate()

      return {
        onUpdateStartDate,
        onUpdateEndDate,
        onUpdateStartTime,
        onUpdateEndTime,
        onUpdateAlert,
        alertItems,
        alertLabel,
        isError,
        currentDate,
        startTime,
        endTime,
        label: computed(() => ({
          'font-semibold text-lg label font-open-sans': true,
        })),
        field: computed(() => ({
          'grid items-center gap-x-2 border-b-2 border-b-solid border-white pb-2': true,
        })),
      }
    },

    /**
     * Set Time fields to max 5 (example: 12:15)
     */
    mounted() {
      document
        .querySelectorAll('.ics-body__start__time input, .ics-body__end__time input')
        .forEach(el => {
          if (el instanceof HTMLInputElement) {
            el.maxLength = 5
          }
        })
    },
  })
