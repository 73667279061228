
  import { defineComponent, reactive, ref } from 'vue'
  import { ScpInput } from '@rmh-media/scp-components'

  export default defineComponent({
    name: 'Location',
    components: {
      ScpInput,
    },
    props: {
      location: {
        required: true,
        default: '',
        type: String,
      },
    },
    emits: ['update'],

    setup(props: Record<string, unknown>, { emit }): Record<string, unknown> {
      props = reactive(props)
      const location = ref(props.location)

      /**
       * Updates the location
       * @param val
       */
      const onUpdateLocation = (val: string) => {
        location.value = val
        onUpdate()
      }

      /**
       * Getting Called by updating 'location' field
       * Emits the data to Create Component
       */
      const onUpdate = () => {
        emit('update', { location: location.value })
      }

      onUpdate()

      return {
        onUpdateLocation,
      }
    },
  })
