import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ics-body flex flex-col gap-y-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_scp_input = _resolveComponent("scp-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_scp_input, {
      class: "w-full ics-body__title",
      label: "Location",
      placeholder: "Location",
      hint: "Mandatory",
      hasValidation: true,
      "model-value": _ctx.location,
      "onUpdate:modelValue": _ctx.onUpdateLocation
    }, null, 8, ["model-value", "onUpdate:modelValue"])
  ]))
}