
  import { defineComponent, ref, Ref, onBeforeUnmount } from 'vue'
  import Create from '@/components/createCalendarEntry/Create.vue'
  import './main.css'

  export default defineComponent({
    name: 'App',
    components: {
      Create,
    },
    setup() {
      const briefingServiceData: Ref<string | null> = ref(null)

      /**
       * Handles postMessage from API
       * @param e
       */
      const handleMessage = (e: MessageEvent) => {
        if (e.data.command === 'briefingServicePostData') {
          briefingServiceData.value = e.data.content
        }
      }

      window.addEventListener('message', handleMessage)

      onBeforeUnmount(() => {
        window.removeEventListener('message', handleMessage)
      })

      return {
        briefingServiceData,
      }
    },
  })
