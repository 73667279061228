
  import { defineComponent, reactive, ref } from 'vue'
  import { ScpInput, ScpTextField } from '@rmh-media/scp-components'

  export default defineComponent({
    name: 'Information',
    components: {
      ScpInput,
      ScpTextField,
    },
    props: {
      title: {
        required: true,
        default: '',
        type: String,
      },
      description: {
        required: true,
        default: '',
        type: String,
      },
    },
    emits: ['update'],

    setup(props: Record<string, unknown>, { emit }): Record<string, unknown> {
      props = reactive(props)
      const title = ref(props.title)
      const description = ref(props.description)
      let errorMessage = ref('')

      /**
       * Validates 'title' and sets errorMessage if invalid
       */
      const validation = () => {
        if (!title.value) {
          errorMessage.value = 'This can not be empty'
        } else {
          errorMessage.value = ''
        }
      }

      /**
       * Updates the title
       * @param val
       */
      const onUpdateTitle = (val: string) => {
        title.value = val
        validation()
        onUpdate()
      }

      /**
       * Updates the description
       * @param val
       */
      const onUpdateDescription = (val: string) => {
        description.value = val
        validation()
        onUpdate()
      }

      /**
       * Getting Called by updating 'title' and 'description' field
       * Emits the data to Create Component
       */
      const onUpdate = () => {
        emit('update', { title: title.value, description: description.value })
      }

      onUpdate()

      return {
        errorMessage,
        onUpdateTitle,
        onUpdateDescription,
      }
    },
  })
