import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ics-body flex flex-col gap-y-2" }
const _hoisted_2 = { class: "pt-2 rounded-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_scp_input = _resolveComponent("scp-input")!
  const _component_ScpTextField = _resolveComponent("ScpTextField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_scp_input, {
      class: "w-full ics-body__title",
      label: "Title",
      placeholder: "Title",
      hint: "Mandatory",
      hasValidation: true,
      "error-message": _ctx.errorMessage,
      "model-value": _ctx.title,
      "onUpdate:modelValue": _ctx.onUpdateTitle
    }, null, 8, ["error-message", "model-value", "onUpdate:modelValue"]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ScpTextField, {
          label: "Description",
          class: "ics-body__description-textfield",
          "model-value": _ctx.description,
          "onUpdate:modelValue": _ctx.onUpdateDescription
        }, null, 8, ["model-value", "onUpdate:modelValue"])
      ])
    ])
  ]))
}