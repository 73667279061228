import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ics-body flex flex-col gap-y-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_scp_input = _resolveComponent("scp-input")!
  const _component_scp_select = _resolveComponent("scp-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["ics-body__start", _ctx.field])
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.label)
      }, "Start", 2),
      _createVNode(_component_scp_input, {
        class: _normalizeClass(["ics-body__start__date w-full", { error: _ctx.isError }]),
        type: "date",
        modelValue: _ctx.currentDate,
        "onUpdate:modelValue": _ctx.onUpdateStartDate
      }, null, 8, ["modelValue", "onUpdate:modelValue", "class"]),
      _createVNode(_component_scp_input, {
        class: _normalizeClass(["ics-body__start__time w-full", { error: _ctx.isError }]),
        modelValue: _ctx.startTime,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.startTime) = $event)),
          _ctx.onUpdateStartTime
        ]
      }, null, 8, ["modelValue", "onUpdate:modelValue", "class"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["ics-body__end", _ctx.field])
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.label)
      }, "End", 2),
      _createVNode(_component_scp_input, {
        class: _normalizeClass(["ics-body__end__date w-full", { error: _ctx.isError }]),
        type: "date",
        modelValue: _ctx.currentDate,
        "onUpdate:modelValue": _ctx.onUpdateEndDate
      }, null, 8, ["modelValue", "onUpdate:modelValue", "class"]),
      _createVNode(_component_scp_input, {
        class: _normalizeClass(["ics-body__end__time w-full", { error: _ctx.isError }]),
        modelValue: _ctx.endTime,
        "onUpdate:modelValue": [
          _cache[1] || (_cache[1] = ($event: any) => ((_ctx.endTime) = $event)),
          _ctx.onUpdateEndTime
        ]
      }, null, 8, ["modelValue", "onUpdate:modelValue", "class"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["ics-body__alert", _ctx.field])
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.label)
      }, "Alert", 2),
      _createVNode(_component_scp_select, {
        items: _ctx.alertItems,
        modelValue: _ctx.alertLabel,
        "onUpdate:modelValue": [
          _cache[2] || (_cache[2] = ($event: any) => ((_ctx.alertLabel) = $event)),
          _ctx.onUpdateAlert
        ],
        class: "m-0 ics-body__alert__dropdown"
      }, null, 8, ["items", "modelValue", "onUpdate:modelValue"])
    ], 2)
  ]))
}