import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ics-header" }
const _hoisted_2 = { class: "ics-header__title font-open-sans font-bold text-lg primary-dark mb-2" }
const _hoisted_3 = { class: "ics-header__description font-open-sans text-sm" }
const _hoisted_4 = { class: "ics-container" }
const _hoisted_5 = { class: "ics-content mt-6 mb-4 bg-primary-light bg-opacity-30 p-2 rounded-lg" }
const _hoisted_6 = { class: "ics-action-btn flex gap-x-2 justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_scp_process_pagination = _resolveComponent("scp-process-pagination")!
  const _component_Information = _resolveComponent("Information")!
  const _component_DateAndTime = _resolveComponent("DateAndTime")!
  const _component_Location = _resolveComponent("Location")!
  const _component_ScpButton = _resolveComponent("ScpButton")!
  const _component_ScpModal = _resolveComponent("ScpModal")!

  return (_openBlock(), _createBlock(_component_ScpModal, {
    class: "ics-app-container relative",
    onCloseModal: _ctx.onClose
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_scp_process_pagination, {
          stages: _ctx.stages,
          currentStageIndex: _ctx.step - 1,
          class: "flex justify-center relative mb-6 mx-auto"
        }, null, 8, ["stages", "currentStageIndex"]),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.description), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createVNode(_component_Information, {
            title: _ctx.data.title,
            description: _ctx.data.description,
            onUpdate: _ctx.onUpdate
          }, null, 8, ["title", "description", "onUpdate"]), [
            [_vShow, _ctx.step === 1]
          ]),
          _withDirectives(_createVNode(_component_DateAndTime, {
            start: _ctx.data.start,
            end: _ctx.data.end,
            alert: _ctx.data.alert,
            onUpdate: _ctx.onUpdate
          }, null, 8, ["start", "end", "alert", "onUpdate"]), [
            [_vShow, _ctx.step === 2]
          ]),
          _withDirectives(_createVNode(_component_Location, {
            location: _ctx.data.location,
            onUpdate: _ctx.onUpdate
          }, null, 8, ["location", "onUpdate"]), [
            [_vShow, _ctx.step === 3]
          ])
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_6, [
        (_ctx.step > 1)
          ? (_openBlock(), _createBlock(_component_ScpButton, {
              key: 0,
              onClick: _ctx.onStepDown,
              design: "border",
              class: "ics-action-btn__back"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Back ")
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.step < 3)
          ? (_openBlock(), _createBlock(_component_ScpButton, {
              key: 1,
              onClick: _ctx.onStepUp,
              class: "ics-action-btn__next",
              disabled: !_ctx.enableContinueButton || _ctx.loading
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Next ")
              ]),
              _: 1
            }, 8, ["onClick", "disabled"]))
          : _createCommentVNode("", true),
        (_ctx.step === 3)
          ? (_openBlock(), _createBlock(_component_ScpButton, {
              key: 2,
              onClick: _ctx.onSave,
              disabled: !_ctx.enableContinueButton || _ctx.loading,
              class: "ics-action-btn__create"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Create ")
              ]),
              _: 1
            }, 8, ["onClick", "disabled"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["onCloseModal"]))
}